<template>
  <b-card :title="`${$t('Sales Invoice')} ${$t('By Branch')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Branch')"
                label-for="branch"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Branch')"
                  rules="required"
                >
                  <v-select
                    v-model="select_id"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="select_option"
                    :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                    input-id="branch-method"
                    class="payment-selector"
                    :clearable="false"
                    :placeholder="$t('Branch')"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false:null"
                  >
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.Start Date')"
                label-for="startDate"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Start Date')"
                  rules="required"
                >
                  <flat-pickr
                    v-model="start_date"
                    class="form-control"
                    :placeholder="$t('General.Start Date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.End Date')"
                label-for="endDate"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.End Date')"
                  rules="required"
                >
                  <flat-pickr
                    v-model="end_date"
                    class="form-control"
                    :placeholder="$t('General.End Date')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('Search') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
            <b-col md="12">
              <!-- Balance Sheet TABLE -->
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="BookOpenIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ `${$t('Sales Invoice')} ${$t('By Branch')} ${$t('General.Report')}` }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="report_data"
                >
                  <template #cell(date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell(due_date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BTable, BCardHeader, BCardTitle, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import reportSale from '@/service/sale/sale-report'
import branch from '@/service/administration/branch'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    BFormInvalidFeedback,
    flatPickr,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      required,
      report_data: [],
      select_id: '',
      select_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.getReportApi()
        }
        this.switchLoc()
      })
    },
    async getReportApi() {
      this.showOverLay = true
      this.report_data = []
      await reportSale.repostAxios({
        report_no: 6,
        from_date: this.start_date,
        to_date: this.end_date,
        organization_branch_id: this.select_id.id,
      }).then(response => {
        this.report_data = response.data
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.ReportSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.ReportError'), 'danger')
      }).finally(() => { this.showOverLay = false })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await branch.getAxios().then(response => {
        this.select_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
